<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 justify-between overflow-auto">
      <ul
        class="w-5/12 overflow-auto p-8 bg-gray-100 text-black"
        aria-label="notes"
      >
        <li>
          Keep your responses positive and upbeat. It’s best to deliver a
          negative message in person.
        </li>
        <li>
          Keep them short. A good response is usually less than 5 seconds long.
        </li>
        <li>
          Be careful with humor. It helps make a good conversation, but it can
          be easily misinterpreted, too.
        </li>
        <li>
          Remember your ultimate goal here. Consider ending your response by
          prompting the user to schedule a tour.
        </li>
        <li>
          Don’t forget to make sure everything your Digital Human says is fully
          compliant with the Fair Housing Act!
        </li>
      </ul>
      <div class="form overflow-auto">
        <div class="form-row">
          <div class="form-col w-1/4">
            <label for="categories">main topic</label>
            <text-input
              v-if="this.category && this.intent && this.subIntent"
              :value="this.category.intent_category_name"
              disabled
            />
            <Dropdown
              v-if="!this.category || !this.intent || !this.subIntent"
              trackBy="intent_category_name"
              label="intent_category_name"
              :options="this.optionalData.categories"
              :value="this.optionalData.category"
              @input="setCategoryValue"
              objectMode
            />
            <span v-if="hasError('optionalData.category')" class="form-error">
              {{ showError('optionalData.category') }}
            </span>
          </div>
          <div class="form-col w-1/4">
            <label for="categories">conversation detail</label>
            <text-input
              v-if="this.category && this.intent && this.subIntent"
              :value="this.intent.suggestion_text"
              disabled
            />
            <Dropdown
              v-if="!this.category || !this.intent || !this.subIntent"
              trackBy="intent_name"
              label="suggestion_text"
              :options="this.optionalData.intents"
              :value="this.optionalData.intent"
              @input="setIntentValue"
              objectMode
            />
            <span v-if="hasError('optionalData.intent')" class="form-error">
              {{ showError('optionalData.intent') }}
            </span>
          </div>
          <div class="form-col w-1/4">
            <label for="categories">next level detail</label>
            <text-input
              v-if="this.category && this.intent && this.subIntent"
              :value="this.subIntent.suggestion_text"
              disabled
            />
            <Dropdown
              v-if="!this.category || !this.intent || !this.subIntent"
              trackBy="intent_name"
              label="suggestion_text"
              :options="this.optionalData.subIntents"
              :value="this.optionalData.subIntent"
              @input="setSubIntentValue"
              objectMode
            />
            <span v-if="hasError('optionalData.subIntent')" class="form-error">
              {{ showError('optionalData.subIntent') }}
            </span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <label for="text">custom response text</label>
            <text-input id="text" v-model="text" multiline />
            <span v-if="hasError('text')" class="form-error">
              {{ showError('text') }}
            </span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <label for="spanish_text"
              >spanish custom translation (optional)</label
            >
            <text-input
              type="spanish_text"
              id="spanish_text"
              v-model="spanish_text"
              multiline
            />
          </div>
        </div>
        <div
          class="font-semibold font-inter p-4 bg-red-100 text-red-700 mb-4"
          v-if="this.spanish_text.length === 0"
        >
          Note: it may take up to a week for Quext to translate your custom
          response for you.
        </div>
        <div
          class="form-row"
          v-for="(responseText, i) in responsesText"
          :key="i"
        >
          <div class="form-col">
            <div class="flex items-center mb-2">
              <button
                class="text-red-700 focus:outline-none mr-1"
                type="button"
                v-on:click="removeResponse(i)"
              >
                <icon name="times" class="w-6 h-6" />
              </button>
              <label :for="'response-' + i.toString()">
                custom response text
              </label>
            </div>
            <text-input
              :id="'response-' + i.toString()"
              v-model="responsesText[i].EN"
              multiline
            />
            <div class="form-row mt-2">
              <div class="form-col">
                <label for="spanish_text"
                  >spanish custom translation (optional)</label
                >
                <text-input
                  type="spanish_text"
                  id="spanish_text"
                  v-model="responsesText[i].ES"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          class="flex items-center border-b font-bold focus:outline-none mt-4 button-color"
          type="button"
          v-on:click="addResponse()"
          v-if="this.responsesText.length < 2"
        >
          add response <icon name="plus" class="w-5 h-5 ml-2 color-text" />
        </button>
      </div>
    </div>
    <modal-footer :footer="footer" :primary="save" />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Icon from '@/components/ui/Icon'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import NotifyMixin from '@/mixins/NotifyMixin'
import ConversationMixin from '@/components/digital_human/ConversationMixin'
import TextInput from '@/components/ui/TextInput';

export default {
  name: 'CreateResponse',
  components: { Loader, Icon, Dropdown, ModalFooter, TextInput },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, ConversationMixin],
  data() {
    return {
      optionalData: {
        category: null,
        intent: null,
        subIntent: null,
        categories: [],
        intents: [],
        subIntents: [],
      },
      text: '',
      spanish_text: '',
      responsesText: [],
      footer: {
        primaryButton: 'create responses',
      },
    }
  },
  methods: {
    fetchCategories() {
      this.setLoading(true)

      this.$dhDataProvider
        .getList('categoriesCustom')
        .then((res) => (this.optionalData.categories = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('customIntents', {
          communityId: this.community?.id,
          category: categoryName,
        })
        .then((res) => (this.optionalData.intents = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchSubIntents(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('subIntents', {
          communityId: this.community?.id,
          intent: intentName,
        })
        .then((res) => (this.optionalData.subIntents = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    setCategoryValue(value) {
      this.optionalData.intents = []
      this.optionalData.subIntents = []
      this.optionalData.category = null
      this.optionalData.intent = null
      this.optionalData.subIntent = null
      if (!value) return
      this.optionalData.category = value
      this.fetchIntents(value?.description)
    },
    setIntentValue(value) {
      this.optionalData.subIntents = []
      this.optionalData.intent = null
      this.optionalData.subIntent = null
      if (!value) return
      this.optionalData.intent = value
      this.fetchSubIntents(value?.intent_name)
    },
    setSubIntentValue(value) {
      this.optionalData.subIntent = null
      if (!value) return
      this.optionalData.subIntent = value
    },
    addResponse() {
      this.responsesText.push({ EN: '', ES: '' })
    },
    removeResponse(i) {
      this.responsesText.splice(i, 1)
    },
    save() {
      if (this.category && this.intent && this.subIntent) {
        this.optionalData.category = this.category
        this.optionalData.intent = this.intent
        this.optionalData.subIntent = this.subIntent
      }

      if (!this.validator.passes()) return

      this.setLoading(true)

      this.$dhDataProvider
        .create('customResponse', {
          data: {
            community_id: this.community?.id,
            intent_name:
              this.optionalData.subIntent?.intent_name === 'general'
                ? this.optionalData.intent.intent_name
                : this.optionalData.subIntent?.intent_name,
            responses: [
              {
                EN: this.text,
                ES: this.spanish_text ? this.spanish_text.trim() : null,
              },
              ...this.responsesText.map((response) => ({
                EN: response.EN,
                ES: response.ES ? response.ES.trim() : null,
              })),
            ].filter((resp) => Boolean(resp.EN.trim())),
          },
        })
        .then(() => {
          if (!this.category || !this.intent || !this.subIntent) {
            this.setCategory(this.optionalData.category)
            this.setIntent(this.optionalData.intent)
            this.setSubIntent(this.optionalData.subIntent)
          }
          this.notifySuccess('The response was created successfully')
          this.redirect('conversations_responses.index')
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
  },
  validations: {
    text: 'required | is_empty',
    'optionalData.category': 'required',
    'optionalData.intent': 'required',
    'optionalData.subIntent': 'required',
  },
  created() {
    this.initValidator()
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
      'text.is_empty': 'this field is empty.',
    })
    this.fetchCategories()
    if (!this.community)
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
  },
}
</script>
<style scoped>
.color-text {
  color: var(--highlightColor500);
}
.button-color {
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}
.form {
  width: 55%;
}
ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
.isDisabled {
  @apply text-gray-400;
}
</style>
